import { request } from 'services';
import apiBatch from 'features/BatchManagement/apiBatch';
const paths = {
  ...apiBatch,
  common: {
    login: 'user/login',
    updateUserProfile: 'user/update',
    findUserProfile: 'user/find',
    getMetaData: 'SPAGE/metadata',

    findSPage: 'SPage/find',
    createSPage: 'SPage/create',
    deleteSPage: 'SPage/deleteOne',
    updateSPage: 'SPage/updateOnePage',
    changePassWord: 'user/changePassWord',

    // findTasks: 'admin/task/findTasks',
    updateTask: 'admin/task/updateTask',
    startTask: 'admin/task/active',
    stopTask: 'admin/task/stopTask',
    deleteTask: 'admin/task/delete',
    createTask: 'admin/task/createTask',
  },
  admin: {},
  model: {
    getALLDataModel: 'model/listModel',
    deleteModel: 'model/deleteModel',
    getModelId: 'model/getModelId',
    updateModel: 'model/updateModel',
    addModelItem: 'model/addModel',
    // uploadFileZip: 'model/uploadFileZip',
    deleteUploadFile: 'model/deleteUploadFile',
    getModelID: 'model/getModelID',
  },
  product: {
    getAllProduct: 'product/listProduct',
    getProductID: 'product/getProductID',
    deleteProduct: 'product/deleteProduct',
    updateProduct: 'product/updateProduct',
    addProduct: 'product/addProduct',
    tryItOut: 'product/tryItOut',
  },

  upload: {
    image: 'api/upload/uploadImage',
    uploadFileZip: 'api/upload/uploadFileZip',
    uploadFile: 'api/upload/uploadFile',
  },
};

let api = {};
let upload = {};

// const APIS = JSON.parse(localStorage.getItem('persists'))
// console.log(JSON.parse(JSON.parse(APIS2).persists).sApis)
// console.log(JSON.parse(JSON.parse(JSON.parse(APIS2).persists).sApis))

const getAPIs = localStorage.getItem('persist:root');
const APIS = (getAPIs && JSON.parse(JSON.parse(getAPIs).persists).sApis) || [];
// console.log(APIS)

const flattenObject = (obj) => {
  let flattened = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

const flattenedPaths = flattenObject(paths);

for (let key in flattenedPaths) {
  api[key] = async function (data, options = {}) {
    let { headers, method, isPublic } = options;
    return await request.request(
      flattenedPaths[key],
      data,
      headers,
      method,
      isPublic
    );
  };
}
APIS?.length &&
  APIS.forEach((temAPI) => {
    api[temAPI.name] = async function (data, options = {}) {
      let { headers, isPublic } = options,
        url = temAPI.route;

      if (temAPI.route.includes('#')) {
        let queries = temAPI.route.split('#');
        url = queries[0];
        for (let index = 1; index < queries.length; index++) {
          url += data[queries[index]];
        }
      }
      return await request.request(url, data, headers, temAPI.method, isPublic);
    };
  });
for (let key in flattenedPaths) {
  upload[key] = async function (formData, method) {
    return await request.upload(flattenedPaths[key], formData, method);
  };
}

export { api, upload, APIS };
