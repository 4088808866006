import { createSlice } from '@reduxjs/toolkit';
import PAGE from 'constant/PAGE.json';
import BATCH from 'features/BatchManagement/BATCH.json';

const initialState = {
  commonPageData: [...BATCH],
};
const commonPageSlice = createSlice({
  name: 'commonPage',
  initialState,
  reducers: {
    setCommonPageData: (state, action) => {
      state.commonPageData = action.payload;
    },
  },
});

export const { setCommonPageData } = commonPageSlice.actions;
export default commonPageSlice.reducer;
